<template>
  <v-card width="350" height="100vh">
    <v-form
      v-model="formValidity"
      @submit.prevent="
        setTaxatorGrid({ name: taxatorGridName, user_ids: taxatorTarget })
      "
    >
      <v-col align="center">
        <h3 class="mt-5 mb-2">Сетка Таксатора</h3>
        <v-row dense>
          <v-card-text class="text-start">Название района таксации</v-card-text>
          <v-text-field
            type="text"
            v-model="taxatorGridName"
            outlined
            hide-details
          ></v-text-field>
          <v-card-text class="text-start">Закрепить за таксатором</v-card-text>
          <v-select
            :items="taxators"
            v-model="taxatorTarget"
            item-text="fullname"
            item-value="id"
            outlined
            multiple
            :rules="[requiredRulesArr]"
          ></v-select>
        </v-row>
        <div class="mt-2">
          <v-row dense justify="center">
            <v-col cols="12" sm="6">
              <v-card-text class="text-center">
                <v-btn
                  type="submit"
                  class="font-weight-bold"
                  :disabled="!formValidity"
                  >Сохранить</v-btn
                >
              </v-card-text>
            </v-col>
            <v-col v-if="!isDraw" cols="12" sm="6" justify="end">
              <v-card-text class="text-start"
                ><v-btn
                  type="button"
                  class="font-weight-bold"
                  v-on:click="$emit('remove-taxator')"
                  >Удалить</v-btn
                ></v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TaxatorGridPassport",

  props: {
    properties: Object,
    setTaxatorGrid: Function,
    isDraw: Boolean
  },

  data() {
    return {
      taxatorGridName: null,
      taxatorTarget: [],
      formValidity: false
    };
  },

  computed: {
    ...mapGetters(["allTaxators"]),
    taxators() {
      let arr = [];
      if (Object.keys(this.allTaxators).length === 0) return arr;
      this.allTaxators.forEach(e =>
        arr.push({ id: e.id, fullname: e.surname + " " + e.name })
      );
      return arr;
    }
  },

  methods: {
    ...mapActions(["getTaxators"]),
    requiredRulesArr(value) {
      if (value instanceof Array && value.length == 0) {
        return "Обязательное поле";
      }
      return !!value || "Обязательное поле";
    }
  },

  watch: {
    properties: function(newValue) {
      this.taxatorGridName = newValue.name;
      this.taxatorTarget = newValue.users
        ? newValue.users.map(el => el.id)
        : null;
    }
  },

  mounted() {
    this.getTaxators();
  }
};
</script>
